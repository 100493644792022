import styled from "styled-components"

export const PostsListSection = styled.section`
    display: flex;
    flex-direction: column;

    @media ${props => props.theme.sizes.laptop} {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 6rem;
    }

`
export const SinglePost = styled.div`
  margin-bottom: 2rem;


  .singlePost {
    &__wrapper {
      display: flex;
      flex-direction: column-reverse;

    }
    &__title {
      font-family: ${props => props.theme.fonts.primary};
      font-weight: 700;
      font-size: 1.5rem;
      color: ${props => props.theme.colors.text};
      margin: 0 0 1rem;

      @media ${props => props.theme.sizes.laptop} {
        font-size: 1.5rem;
      }
      @media ${props => props.theme.sizes.laptopL} {
        font-size: 1.8rem;
      }

      &--link {
        text-decoration: none;
      }
    }
    &__expert {
      font-family: ${props => props.theme.fonts.secondary};
      font-weight: 200;
      line-height: 27px;
      color: ${props => props.theme.colors.text};
    }
    &__image {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      &-link {
        height: 100%;
      }

      .gatsby-image-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        max-height: 456px;
      }
    }
  }
`

export const CategoriesList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: row;
  margin: 1rem 0;
  padding-left: 0;

  .singleCategorie {
    font-family: ${props => props.theme.fonts.primary};
    font-weight: 900;
    color: ${props => props.theme.colors.text};
    font-size: 1rem;
    margin-right: 0.7rem;
    line-height: 1;
  }
`
