import styled from "styled-components"

export const StickyPostSection = styled.section`
  /* min-height: 60vh;
  display: flex;
  align-items: center; */

  .stickyPost {
    &__wrapper {
      display: flex;
      flex-direction: column-reverse;
      grid-gap: 70px;

      @media ${props => props.theme.sizes.laptop} {
        flex-direction: row;
      }
    }
    &__content {
      width: 100%;

      @media ${props => props.theme.sizes.laptop} {
        max-width: 50%;
        padding-right: 3rem;
      }
    }
    &__image {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      @media ${props => props.theme.sizes.laptop} {
        max-width: 50%;
      }

      .gatsby-image-wrapper {
        width: 100%;
        height: 100%;
      }
    }
    &__title {
      font-family: ${props => props.theme.fonts.primary};
      font-weight: 900;
      color: ${props => props.theme.colors.text};
      font-size: 1.5rem;
      margin: 1rem 0 0;
      line-height: 2rem;
      margin-bottom: 1rem;

      @media ${props => props.theme.sizes.tablet} {
        font-size: 2rem;
        line-height: 2.4rem;
        margin-bottom: 1rem;
      }
      @media ${props => props.theme.sizes.laptop} {
        font-size: 1.3rem;
        line-height: 2rem;
      }
      @media ${props => props.theme.sizes.laptopL} {
        font-size: 2rem;
        line-height: 2.4rem;
        margin-bottom: 1rem;
      }
    }
    &__excerpt {
      font-family: ${props => props.theme.fonts.secondary};
      font-weight: 200;
      font-size: 1.3rem;
      margin: 0;
      line-height: 2rem;

      @media ${props => props.theme.sizes.tablet} {
        font-size: 1.6rem;
        line-height: 2.4rem;
        padding-right: 2rem;
      }
      @media ${props => props.theme.sizes.laptop} {
        font-size: 1.1rem;
      }
      @media ${props => props.theme.sizes.laptopL} {
        font-size: 1.4rem;
        line-height: 2.1rem;
        padding-right: 2rem;
      }
    }
  }
`
