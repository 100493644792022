import React from "react"

import { StickyPostSection } from "./StickyPost.style"
import Container from "../../Container"
import { Link } from "gatsby"
import ButtonPrimary from "../../atoms/ButtonPrimary"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

const StickyPost = props => {

  const postData = props.post

  return (
    <StickyPostSection className="stickyPost">
      <Container>
        {/* <div className="about__content" dangerouslySetInnerHTML={{ __html: data.allWpPage.nodes[0].AcfHome.about }}/> */}
        <div className="stickyPost__wrapper">
          <div className="stickyPost__image">
            <Link className="singlePost__image-link" to={postData.uri}>
              <GatsbyImage
                image={getImage(postData.ACF_post.thumbnailImage.localFile)}
                alt={postData.title}
                title={postData.title}
              />
            </Link>
          </div>
          <div className="stickyPost__content">
            <h2
              className="stickyPost__title"
              dangerouslySetInnerHTML={{ __html: postData.title }}
            ></h2>
            <div
              className="stickyPost__excerpt"
              dangerouslySetInnerHTML={{ __html: postData.excerpt }}
            ></div>
            <ButtonPrimary
              text="przeczytaj"
              anchor={postData.uri}
            />
          </div>
        </div>
      </Container>
    </StickyPostSection>
  )
}
export default StickyPost
