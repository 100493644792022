import React from "react"
import Layout from "../../components/layout"
import { SEO } from "../../components/seo"
import { useStaticQuery, graphql } from "gatsby"

//Sections
import StickyPost from "../../components/organisms/blog/StickyPost"
import PostsList from "../../components/organisms/blog/PostsList"
import PostCategories from "../../templates/blog/PostCategories"
import Container from "../../components/container"

const BlogPost = props => {
  console.log(props.pageContext)
  const SEO = props.pageContext.seo
  const data = useStaticQuery(graphql`
    query PostCategories {
      allWpCategory(filter: { slug: { nin: "bez-kategorii" } }) {
        nodes {
          name
        }
      }
    }
  `)

  const [state, setState] = React.useState({
    filteredCategory: [],
  })

  const filterCategories = category => event => {
    let fCats = state['filteredCategory'];
    if(fCats.includes(category))
      fCats.splice(fCats.indexOf(category), 1);
    else
      fCats.push(category);

    setState({ ...state, ['filteredCategory']: fCats })
  }

  return (
    <Layout seoData={SEO} seoUrl={props.pageContext.uri}>
      <StickyPost post={props.pageContext.ACF_page_blog.wpisy[0]} />
      <Container>
        <PostCategories list={data.allWpCategory.nodes} filterCategories={filterCategories} filteredCategory={state['filteredCategory']}/>
        <PostsList filteredCategory={state['filteredCategory']}/>
      </Container>
    </Layout>
  )
}
export default BlogPost


export const Head = props => {
  return (
   <SEO title={props.pageContext.seo.title} description={props.pageContext.seo.opengraphDescription} image={props.pageContext.seo?.opengraphImage?.localFile.childImageSharp.fixed.src} pathname={props.pageContext.seo.opengraphUrl} />
  )
}