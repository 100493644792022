import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { PostsListSection, SinglePost, CategoriesList } from "./PostsList.style"
import Container from "../../container"
import { Link } from "gatsby"
import ButtonPrimary from "../../atoms/ButtonPrimary"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

const PostsList = props => {
  const data = useStaticQuery(graphql`
    query PostsListQuery {
      allWpPost(
        sort: { fields: date, order: DESC }
        filter: { isSticky: { in: false } }
      ) {
        nodes {
          title
          isSticky
          excerpt
          uri
          slug
          categories {
            nodes {
              slug
              name
            }
          }
          ACF_post {
            thumbnailImage {
              title
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    avifOptions: { lossless: true, quality: 100 }
                    formats: PNG
                  )
                }
              }
            }
          }
          featuredImage {
            node {
              title
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    avifOptions: { lossless: true, quality: 100 }
                    formats: WEBP
                  )
                }
              }
            }
          }
        }
      }
    }
  `)

  const BlogPosts = data.allWpPost.nodes
  .filter(post => !props.filteredCategory.length || post.categories.nodes.find(item => props.filteredCategory.includes(item.name)))
  .map(post => (
    <SinglePost className="singlePost">
      <div className="singlePost__wrapper">
        <div className="singlePost__content">
          <CategoriesList>
            {post.categories.nodes.map(item => (
              <li className="singleCategorie">#{item.name}</li>
            ))}
          </CategoriesList>
          <Link className="singlePost__title--link"  to={post.uri}>
            <h3
              className="singlePost__title"
              dangerouslySetInnerHTML={{ __html: post.title }}
            ></h3>
          </Link>
          <div
            className="singlePost__expert"
            dangerouslySetInnerHTML={{ __html: post.excerpt }}
          ></div>
          <ButtonPrimary
            text="przeczytaj"
            anchor={post.uri}
          />
        </div>
        {post.ACF_post.thumbnailImage && (
          <div className="singlePost__image">
            <Link className="singlePost__image-link" to={post.uri}>
              <GatsbyImage
                image={getImage(post.ACF_post.thumbnailImage.localFile)}
                alt={post.title}
                title={post.title}
              />
            </Link>
          </div>
        )}
      </div>
    </SinglePost>
  ))

  return <PostsListSection className="stickyPost">{BlogPosts}</PostsListSection>
}
export default PostsList
