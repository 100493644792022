import React from "react"
import styled from "styled-components"

const Categories = styled.div`
  margin: 4rem 0;


  .category__title {
    color: ${props => props.theme.colors.black};
    margin: 0 0 1rem;
    font-size: 1.8rem;
  }

  @media ${props => props.theme.sizes.tablet} {

    .category__title {
      margin: 0 0 2rem;
      font-size: 2rem;
    }
  }
  @media ${props => props.theme.sizes.laptop} {
    .category__title {
      margin: 0 0 1.5rem;
      font-size: 1.5rem;
    }
  }
  @media ${props => props.theme.sizes.laptopL} {
    .category__title {
      margin: 0 0 2rem;
      font-size: 2.5rem;
    }
  }
`

const CategoryList = styled.ul`
  margin: 0;
  padding-left: 0;
  display: flex;
  flex-direction: row;
  list-style: none;
  flex-wrap: wrap;
`
const CategoryListItem = styled.li`
  color: ${props => props.theme.colors.black};
  font-family: ${props => props.theme.fonts.primary};
  font-weight: 500;
  font-size: 2rem;
  margin-right: 1.2rem;
  position: relative;

  &.active {
    color: ${props => props.theme.colors.primary};
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: -3px;
      right: 0;
      width: 100%;
      height: 3px;
      background: ${props => props.theme.colors.primary};
    }
  }
  &:hover {
    cursor: pointer;
  }

  @media ${props => props.theme.sizes.laptop} {
    font-size: 1.4rem;
    margin-right: 1.5rem;
  }
  @media ${props => props.theme.sizes.laptopL} {
    font-size: 1.6rem;
    margin-right: 4.5rem;
  }
`

const PostCategories = props => {
  const listItems = props.list.map(item => (
    <CategoryListItem
      key={item.slug}
      onClick={props.filterCategories(item.name)}
      className={(props.filteredCategory.includes(item.name) ? 'active' : '')}
    >
      {item.name}
    </CategoryListItem>
  ))

  return (
    <Categories>
      <h4 className="category__title">kategorie</h4>
      <CategoryList>{listItems}</CategoryList>
    </Categories>
  )
}

export default PostCategories
