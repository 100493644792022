import React from "react"
import styled from "styled-components"

const StyledContainer = styled.div`
    margin: ${props => props.primary ? "0 8vw" : "0 4vw"};
    max-width: 100%;
    width: -webkit-fill-available;


    @media ${props => props.theme.sizes.laptop} {
        margin: ${props => props.primary ? "0 12vw" : "0 6vw"};
    }

`

export default function Container({ children, margin }) {
    return (
        <StyledContainer primary={margin} >
            {children}
        </StyledContainer>
    )
}